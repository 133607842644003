/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // The slider being synced must be initialized first
        $('#carousel').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          itemWidth: 120,
          itemMargin: 5,
          asNavFor: '#slider'
        });

        $('#slider').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          sync: "#carousel"
        });

        $('.map').click(function () {
            $('.map iframe').css("pointer-events", "auto");
        });

        $(function() {
          var $sidebar   = $("#services-nav"),
          $window    = $(window),
          offset     = $sidebar.offset(),
          topPadding = 15;

          $window.scroll(function() {
              if ($window.scrollTop() > offset.top) {
                  $sidebar.stop().animate({
                      marginTop: $window.scrollTop() - offset.top + topPadding
                  });
              } else {
                  $sidebar.stop().animate({
                      marginTop: 0
                  });
              }
          });
        });

        $('a[href^="#"]').on('click',function (e) {
          e.preventDefault();

          var target = this.hash;
          var $target = $(target);

          $('html, body').stop().animate({
              'scrollTop': $target.offset().top
          }, 900, 'swing', function () {
              window.location.hash = target;
          });
        });

        //Ajax loading posts

        function find_page_number(element) {
          //element.find('span').remove();
          return parseInt( element.find('span').html() );
        }

        // http://stackoverflow.com/questions/14636536/how-to-check-if-a-variable-is-an-integer-in-javascript
        function isInt(value) {
          return !isNaN(value) &&
                 parseInt(Number(value)) === value &&
                 !isNaN(parseInt(value, 10));
        }

        $(document).on( 'click', '.btn-load-more', function(event) {
          event.preventDefault();

          //grab the next page from .the btn-load-more
          page = find_page_number( $(this).clone() );

          $.ajax({
            url: ajaxpagination.ajaxurl,
            type: 'post',
            data: {
              action: 'jmc_ajax_pagination',
              query_vars: ajaxpagination.query_vars,
              page: page
            },
            beforeSend: function() {
              //$('.tiles').find('article').remove();
              //$('.main .btn-load-more').remove();
              $('.btn-load-more i').removeClass();
              $('.btn-load-more i').addClass('fa fa-spinner fa-pulse');
              //$('.tiles').append( '<div class="page-content" id="loader">Loading New Posts...</div>' );
            },
            success: function(html){
              //$('html body').scrollTop(0);
              $('.tiles #loader').remove();
              //$('.tiles').append(html);

              /*var $grid = $('.tiles').masonry({
                itemSelector: '.tile'
              });*/

              if (html === "0"){
                $('.btn-load-more').remove();
                $('#items-container').append("<p class='load-more-end'>End of posts</p>");
              }
              else {
                $html = $( html );

                $('#items_container').append($html);

                nextPage = page + 1;

                $('.btn-load-more i').removeClass();
                $('.btn-load-more i').addClass('fa fa-plus');
                $('.btn-load-more').find('span').text(nextPage);


                var parser = document.createElement('a');
                parser.href = document.location.href;
                pathParts = parser.pathname.split("/");
                pathParts = $.grep(pathParts,function(n){ return(n); });

                pages = pathParts.slice(-2);
                //if (){
                if ((pages[0] === "page") && (typeof parseInt(pages[1]) === 'number')){
                  //we're starting from a paged page
                  current_page = parseInt(pages[1]) + 1; //the page we came from + 1 = current page
                  baseurl = pathParts.slice(0, -1); //baseurl minus the page number
                  baseurl.push(current_page); //add the new page number

                  addressBarURL = '/'+baseurl.join([separator = '/']);
                  //rebuild URL and push the state
                  history.pushState({}, '', addressBarURL);

                  //update Google Analytics
                  ga('send', 'pageview', addressBarURL);
                }
                else {
                  //we're coming from page 1, so just add the state
                  addressBarURL = 'page/'+page+'/';
                  history.pushState({}, '', addressBarURL);

                  //update Google Analytics
                  ga('send', 'pageview', addressBarURL);
                }
              }
            }
          });
        }); //load more button click

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'services': {
      init: function() {



      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
